@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;
.downloadAppWrapper{
    width: 100%;
    padding: 30px 0;
    .appWrapper{
        margin-top: 0 !important;
        .appBtns{
            position: relative;
            z-index: 999;
        }
    }
}


