@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;
.worryFreeWrapper{
    width: 100%;
    padding: 30px 0;
    .worryFreeContent{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background: linear-gradient(257.79deg, #FF7F2F 4.72%, #4B99C0 93.75%);
        padding: 72px 56px;
        @include breakpoint-down(sm) {
            flex-direction: column;
            padding: 25px;
        }
        .worryContentLeft{
            max-width: 45%;
            color: $white-color;
            @include breakpoint-down(sm) {
                max-width: 100%;
            }
            .title{
                font-size: 36px;
                line-height: 45px;
                font-weight: 700;
                @include breakpoint-down(sm) {
                    font-size: 25px;
                    line-height: 40px;
                    text-align: center;
                }
            }
            .info{
                font-size: 16px;
                line-height: 32px;
                font-weight: 500;
                margin: 20px 0 12px 0;
            }
        }
        .worryContentRight{
            max-width: 55%;
            margin-left: 93px;
            color: $white-color;
            @include breakpoint-down(sm) {
                max-width: 100%;
                margin: 0;
            }
            .contentWrap{
                width: 100%;
                margin: 8px 0;
                padding: 12px 20px;
                border-left: 2px solid transparent;
                font-family: "Plus Jakarta Sans", serif;
                &:hover{
                    transition: all 0.5s ease;
                    background: rgba(0, 0, 0, 0.4);
                    border-left: 2px solid $white-color;
                }
            }
            .title{
                font-size: 14px;
                line-height: 24px;
                font-weight: 700;
                margin-bottom: 5px;
                font-family: "Inter", serif;
            }
            .info{
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
                margin-bottom: 0;
                font-family: "Inter", serif;
            }
        }
    }
}
