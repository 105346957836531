@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;

.featuresWrapper{
  width: 100%;
  background-image: linear-gradient($gradient-primary, $gradient-secondary);
  overflow: hidden;
  // @include breakpoint-down(xs) {
  //   padding: 30px 15px;
  // }
  .featuresInnWrapper{
    background-size: 70%;
    background-position: 0px 30px;
    @include breakpoint-down(sm) {
      padding-top: 10px;
      background: none;
    }
  .topTitleRight{
    font-size: 40px;
    line-height: 45px;
    color: $black-color;
    margin-bottom: 30px;
    font-weight: 600;
    @include breakpoint-down(sm) {
      margin-bottom: 15px;
      font-size: 30px;
      line-height: 40px;
      text-align: center;
    }
  }
  .topTitleLeft{  
    font-size: 22px;
    line-height: 33px;
    color: $gray-color;
    @include breakpoint-down(sm) {
      font-size: 18px;
      line-height: 25px;
    }
  }
  .carouselFeatures{
    width: 100%;
    position: relative;
    padding-bottom: 30px;
    .itemCover{
    .item{
      .bannerImg{
        position: relative;
        overflow: hidden;
        .image {
          opacity: 1;
          display: block;
          width: 100%;
          height: auto;
          backface-visibility: hidden;
          -webkit-transform: scale(1);
          transform: scale(1);
          -webkit-transition: .3s ease-in-out;
          transition: .5s ease-in-out;
        }
        .middle {
          transition: .5s ease;
          opacity: 0;
          background: $base-primary-rgb;
          background: $base-primary-rgba; /* Black see-through */
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          text-align: center;
          position: absolute;
          z-index: 9;
        }
        &::after{
          content: '';
          float: left;
          position: absolute;
          bottom: 0;
          right: 0;
          border-bottom: 38px solid $blue-color;
          border-left: 38px solid transparent;
        }
        &:hover .image{
          -webkit-transform: scale(1.1);
	        transform: scale(1.1);
        }
        &:hover .middle {
          opacity: 1;
          height: 100%;
          width: 100%;
        }
      }
      .bannerText{
        background: #ffffff;
        // box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.3);
        border: 1px solid #e1e1e1;
        padding: 1rem;
        height: 220px;
        border-radius: 0 0 8px 8px;
        margin-bottom: 1rem;
        box-shadow: 0 8px 8px -4px #e1e1e1;
        .featuresTitle{
          font-size: 25px;
          width: 100%;
          color: #0E0F0F;
          margin: 0px 0 9px 0;
          font-weight: 500;
        }
        .featuresText{
          font-size: 16px;
          width: 100%;
          line-height: 24px;
          color: #0E0F0F;
          @include breakpoint-down(md) {
            font-size: 18px;
            line-height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical; 
          }
        }
      }
      }
    }
    .owl-nav{
      position: absolute;
      top: 25%;
      width: 100%;
      .owl-prev{
        position: absolute;
        left: -2.2rem;
        width: 58px;
        height: 58px;
        font-size: 0;
        background-color: $white-color;
        border-radius: 50%;
        box-shadow: 0 0 80px 0 rgba(0,0,0,0.50);
        span{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 10px;
          background: url('../../Assets/Images/arrow_icon.png') no-repeat;
          background-size: 21px;
          transform: rotate(180deg);
          background-position: center center;
        }
        &:hover span{
          background: url('../../Assets/Images/arrow_icon_hover.png') no-repeat;
          transition: all 0.5s ease;
          background-size: 21px;
          transform: rotate(180deg);
          background-position: center center;
        }
        @include breakpoint-down(xs) {
          width: 45px;
          height: 45px;
        }
      }
      .owl-next{
        position: absolute;
        right: -2.2rem;
        width: 58px;
        height: 58px;
        font-size: 0;
        background-color: $white-color;
        border-radius: 50%;
        box-shadow: 0 0 80px 0 rgba(0,0,0,0.50);
        span{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 10px;
          background: url('../../Assets/Images/arrow_icon.png') no-repeat;
          background-size: 21px;
          transform: rotate(0deg);
          background-position: center center;
        }
        &:hover span{
          background: url('../../Assets/Images/arrow_icon_hover.png') no-repeat;
          transition: all 0.5s ease;
          background-size: 21px;
          transform: rotate(0deg);
          background-position: center center;
        }
        @include breakpoint-down(xs) {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
  .appWrapper{
    width: 100%;
    background:$offgray-color url('../../Assets/Images/exploreBg.jpg') no-repeat;
    height: 479px;
    width: 100%;
    position: relative;
    background-size: cover;
    @include breakpoint-down(sm) {
      margin-top: 30px;
      height: auto;
    }
    &__right{
      height: 100%;
      padding: 0 70px;
      flex-direction: column;
      display: flex;
      justify-content: center;
      @include breakpoint-down(sm) {
        align-items: flex-start;
        padding: 0 25px;
      }
      h2{
        font-size: 30px;
        line-height: 40px;
        color: $white-color;
        width: 100%;
        font-weight: 600;
        @include breakpoint-down(sm) {
          padding: 20px 0 0 0;
          font-size: 25px;
          line-height: 40px;
          text-align: center;
        }
      }
      p{
        font-size: 16px;
        line-height: 24px;
        color: $white-color;
        margin: 8px 0 20px 0;
        letter-spacing: 0.5px;
        @include breakpoint-down(sm) {
          margin-top: 10px;
          font-size: 14px;
          text-align: center;
        }
      }
      .appBtns{
        width: 100%;
        display: flex;
        @include breakpoint-down(sm) {
          margin-bottom: 20px;
          align-items: center;
          justify-content: center;
        }
        img{
          width: 170px;
          margin-right: 18px;
          @include breakpoint-down(xs) {
            width: 125px;
          }
        }
      }
    }
    &__left{
      position: relative;
      height: 479px;
      overflow: hidden;
      @include breakpoint-down(sm) {
        height: 479px;
        margin-top: 20px;
      }
      @include breakpoint-down(xs) {
        height: 300px;
      }
      .innerImage{
        background: url('../../Assets/Images/exploreTop.png') no-repeat;
        background-position: center;
        background-size: 74%;
        transition: 0.5s ease-in-out;
        height: 106%;
        width: 100%;
        transform: rotate(-15deg) scale(1.2);
        background-position: 80% 26%;
        @include breakpoint-down(sm) {
          background-size: 80%;
        }
        @include breakpoint-down(xs) {
          background-position: center bottom;
        }
        &:hover{
          transform: rotate(0deg) scale(1);
          background-size: 71%;
        }
      }
    }

    &::after{
      content: ''; 
      float: left;
      background-position: 91% 71%;
      height: 300px;
      z-index: 9;
      position: absolute;
      right: -13rem;
      bottom: -7rem;
      width: 100%;
      background-size:265px;
      @include breakpoint-down(md) {
        position: absolute;
        right: -3rem;
        background-size: 200px;
        background-position: 100%;
      }
      @include breakpoint-down(sm) {
        display: none;
      }
    }
  }
}
}