$base-color: #FF7824;
$base-primary: #FF9350;
$base-primary-rgb: rgb(255, 119, 34);
$base-primary-rgba: rgba(255, 119, 34, 0.5);
$blue-color: #4B98C0;
$blue-primary: #0094DF;
$black-color: #0E0F0F;
$white-color: #ffffff;
$gray-color: #676767;
$gray-secondary: #87888C;
$offgray-color: #e1e1e1;
$gradient-primary: #F4FEFE;
$gradient-secondary: #FFF0EC;
$border-color: #E3E4E5;
$gray-text: #667085;

$navbackground-color: rgb(153, 153, 153, 0.3);

$breakpoints-up: (
  sm: 640px,  
  md: 1000px,
  lg: 1184px,
  xl: 1440px,
  xxl: 1600px,
);

$breakpoints-down: (
  xs: 639px,
  sm: 999px,
  md: 1183px,
  lg: 1339px,
  xl: 1599px,
);
