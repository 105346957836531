@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;
.siteMapWrapper{
  .container{
    width: 1030px;
    @include breakpoint-down(sm) {
      width: 100%;
    }
    .staticInfo {
      width: 100%;
      height: 100%;
      margin-top: 100px;
      h1 {
        margin-bottom: 1rem;
        font-size: 36px;
        line-height: 50px;
        color: #0E0F0F;
        font-weight: 700;
        @include breakpoint-down(sm) {
          font-size: 28px;
          line-height: 40px;
          text-align: center;
        }
      }
      .digitalIntroSocial{
        padding-bottom: 30px;
        margin-bottom: 40px;
        border-bottom: 1px solid #e1e1e1;
        p{
          margin-bottom: 0;
        }
       
      }
      .digitalTiles{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin-bottom: 50px;
        @include breakpoint-down(sm) {
          display: inherit;
        }
        .tilesSocial:nth-child( even ) {
          background: #FDF2EF;
        }
        .tilesSocial:nth-child( odd ) {
          background: #EFFAFF;
        }
        .tilesSocial{
          padding: 28px;
          @include breakpoint-down(sm) {
            margin-bottom: 20px;
          }
          h4{
            font-size: 34px;
            line-height: 41px;
            font-weight: 600;
            color: #0E0F0F;
          }
          p{
            font-size: 18px;
            line-height: 25px;
            color: #0E0F0F;
          }
        }
      }
      .staticBottomInfo{
        margin: 66px 0 110px 0;
        @include breakpoint-down(sm) {
          margin: 40px 0 40px 0;
        }
        h4{
          margin-bottom: 16px;
          font-size: 18px;
          line-height: 23px;
          color: #0E0F0F;
        }
        p{
          font-size: 18px;
          line-height: 25px;
          color: #0E0F0F;
        }
      }
    }
  }
}
