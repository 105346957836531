@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;
.wingmanWrapper{
    width: 100%;
    padding:30px 0;
    .wingContentWrap{
        width: 100%;
        min-height: 480px;
        position: relative;
        background:linear-gradient(180deg, #000B1F 0%, #000E25 74.11%, #001E3A 79.7%, #011C39 100%);
        @include breakpoint-down(sm) {
            min-height: 700px;
        }
    }
    .wingContent{
        width: 100%;
        height: 100%;
        background: 
        url('../../Assets/Images/wingman.png'),
        url('../../Assets/Images/wingmanbike.png');
        background-repeat: no-repeat;
        background-size: 25%, 45%;
        background-position: left bottom, right center;
        position: absolute;
        @include breakpoint-down(sm) {
            background: url('../../Assets/Images/wingmanMobile.png');
            background-repeat: no-repeat;
            background-size: 200px;
            background-position: center bottom;
        }
        .innerInfo{
            height: 100%;
            width: 100%;
            max-width: 615px;
            margin: 0 auto;
            padding: 39px 0;
            @include breakpoint-down(sm) {
                max-width: 100%;
                padding: 35px 20px 215px 20px;
            }
            .wingText{
                font-size: 20px;
                line-height: 32px;
                color: $white-color;
                width: 612px;
                @include breakpoint-down(sm) {
                    width: 100%;
                    font-size: 18px;
                    line-height: 25px;
                    text-align: center;
                }
                span{
                    color: $base-primary;
                }
            }
            .wingTitle{
                font-size: 32px;
                line-height: 45px;
                font-weight: 700;
                color: $white-color; 
                margin-top: 30px;
                @include breakpoint-down(sm) {
                    font-size: 18px;
                    line-height: 28px;
                    margin: 30px 0;
                    text-align: center;
                }
            }
            .badgesList{
                width: 100%;  
                @include breakpoint-down(sm) {
                    text-align: center;
                }
                .customBadge{
                    background: $white-color;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 500;
                    color: $black-color;
                    border-radius: 0;
                    margin-right: 12px;
                    @include breakpoint-down(sm) {
                        font-size: 10px;
                        margin: 0 5px 10px 0;
                    }
                }
            }
            .detailsInfo{
                font-size: 18px;
                line-height: 32px;
                font-weight: 400;
                width: 565px;
                margin-top: 12px;
                font-family: "Plus Jakarta Sans", serif;
                color: $white-color;  
                @include breakpoint-down(sm) {
                    width: 100%;
                    font-size: 16px;
                    line-height: 25px;
                    text-align: center;
                }
            }
            .customBtn{
                background: $blue-primary;
                border-radius: 0;
                border: 1px solid $blue-primary;
                height: 52px;
                max-width: 285px;
                display: flex;
                align-items: center;
                @include breakpoint-down(sm) {
                    width: 100%;
                    margin-top: 10px;
                    margin: 0 auto;
                }
            }
            .soonText{
                margin-top: 10px;
                color: $white-color;
                @include breakpoint-down(sm) {
                    text-align: center;
                }
            }
        }
    }
}