
@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;
.TrendingProWrapper{
    width: 100%;
    padding: 30px 0;
    .cardList{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include breakpoint-down(sm) {
            flex-wrap: wrap;
        }
        .card{
            width: 100%;
            margin: 0 5px;
            @include breakpoint-down(md) {
                max-width: 48%;
                margin-bottom: 15px;
                flex-wrap: wrap;
            }
            @include breakpoint-down(xs) {
                max-width: 100%;
            }
            .card-title{
                font-size: 14px;
                height: 30px;
                font-weight: 600;
                margin-bottom: 1rem;
            }
            img{
                height: 300px;
                padding: 10px;
                object-fit: contain;
            }
            .btn-primary{
                background: #0094DF;
                border-color: #0094DF;
            }
        }
    }
    
}