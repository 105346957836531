
@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;
.ourFeaturesWrapper{
    width: 100%;
    padding: 50px 0 30px 0;
    @include breakpoint-down(md) {
        padding: 50px 20px;
    }
    .topTitleRight {
        height: 100%;
        display: flex;
        align-items: center;
        @include breakpoint-down(md) {
            text-align: center;
        }
    }
    .topTitleLeft{
        @include breakpoint-down(md) {
            text-align: left;
        }
    }
}