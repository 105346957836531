@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;

.doRideWrapper{
    width: 100%;
    margin-bottom: 100px;
    @include breakpoint-down(sm) {
        margin-bottom: 30px;
    }
    .doRideContent{
        .title{
            width: 650px;
            font-size: 120px;
            line-height: 123px;
            font-weight: 700;
            color: $gray-secondary;
            @include breakpoint-down(sm) {
                width: 100%;
                font-size: 45px;
                line-height: 60px;
                text-align: center;
            }
            span{
                color: $base-primary;
            }
        }
    }
}