@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;
.inTouchWrapper{
    width: 100%;
    margin: 30px 0 95px 0;

    .inTouchContent{
        width: 100%;
        height: 100%;
        background: $white-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include breakpoint-down(sm) {
            flex-direction: column;
        }
        .mapSide{
            width: 100%;
            position: relative;
            background: url('../../Assets/Images/locationmap.jpg') no-repeat;
            background-size: cover;
            height: 100%;
            @include breakpoint-down(sm) {
                height: 355px;
            }
            .mapPopup{
                position: absolute;
                z-index: 999;
                background: $white-color;
                padding: 20px 16px;
                border-radius: 16px;
                top: 35%;
                left: 35%;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
                &::after{
                    content: '';
                    background: url('../../Assets/Images/polygon.png') no-repeat;
                    display: flex;
                    width: 35px;
                    height: 18px;
                    position: absolute;
                    bottom: -15px;
                    left: 80px;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
                }
                .logoMapImg{
                    width: 70px;
                    margin-bottom: 15px;
                }
                .title{
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 700;
                    margin-bottom: 5px;
                    color: #101828;
                }
                .subTitle{
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: 500;
                    margin-bottom: 17px;
                    color: rgba(16, 24, 40, 0.5);
                }
                .link{
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: 700;
                    text-decoration: none;
                    color: #101828;
                }
            }
        }
        .formSide{
            width: 100%;
            padding: 30px 44px 44px 44px;
            @include breakpoint-down(sm) {
                padding: 25px 20px;
            }
            .formTitle{
                font-size: 40px;
                line-height: 60px;
                font-weight: 700;
                @include breakpoint-down(sm) {
                    text-align: center;
                }
            }
            .formSubTitle{
                font-size: 20px;
                line-height: 30px;
                font-weight: 400;
                margin-bottom: 30px;
                color: $gray-text;
                @include breakpoint-down(sm) {
                    text-align: center;
                    font-weight: 500;
                }
            }
            .countryNumber{
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #dee2e6;
                border-radius: 0.375rem;
                position: relative;
                .selectInput{
                    border: none;   
                    border-radius: 0.375rem 0 0 0.375rem;
                    border-right:  1px solid #dee2e6;
                    
                }
                .numberInput{
                    border: none;
                    border-radius: 0 0.375rem 0.375rem 0;
                }
            }
            .withFull{
                width: 100%;
                height: 56px;
                border-radius: 0;
                margin-top: 20px;
                background-color: $blue-primary;    
            }
            .formTitle{
                width: 100%;
                font-size: 40px;
                line-height: 60px;
                font-weight: 700;
                margin-bottom: 15px
            }
            .formSubTitle{
                font-size: 24px;
                line-height: 30px;
                font-weight: 700;
                margin-bottom: 12px
            }
            .addressText{
                font-size: 20px;
                line-height: 30px;
                color: #667085;
                margin-bottom: 25px;
            }
            .getDirectBtn{
                display: flex;
                height: 46px;
                max-width: 160px;
                padding: 12px;
                border-radius: 8px;
                margin-bottom: 25px;
                display: flex;
                align-items: center;
                background: #E2ECF6;
                text-decoration: none;
                img{
                    width: 16px;
                    height: 16px;
                    margin-left: 5px;
                    margin-top: 0;
                }
            }
        }
        .contactInfo{
            width: 100%;
            .contactMail{
                width: 100%;
                display: inline-block;
                font-size: 20px;
                line-height: 25px;
                font-weight: 700;
                margin-bottom: 15px;
                padding-left: 25px;
                color: #101828;
                position: relative;
                &::before{
                    content: '';
                    display: flex;
                    background: url('../../Assets/Images/contactmail.svg') no-repeat;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 5px;
                    left: 0;
                }
            }
            .contactLink{
                width: 100%;
                display: inline-block;
                font-size: 20px;
                line-height: 25px;
                font-weight: 700;
                margin-bottom: 15px;
                padding-left: 25px;
                color: #101828;
                position: relative;
                &::before{
                    content: '';
                    display: flex;
                    background: url('../../Assets/Images/contactcall.svg') no-repeat;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 5px;
                    left: 0;
                }
            }
        
        }
        .socialLinks{
            width: 100%;
            .title{
                font-size: 14px;
                line-height: 17px;
                color: #101828;
                margin: 10px 0 16px 0;
            }
                .socialList{
                    list-style-type: none;
                    display: flex;
                    margin: 0;
                    padding: 0;
                    .items{
                        a{
                            margin-right: 16px;
                            img{
                                width: 20px;
                            }
                        }
                    }
                
            }
        }
    }
}