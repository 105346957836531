@use '../../ThemeConfig/mixins.scss' as *;
@use "../../ThemeConfig/Variables.scss" as *;

.bannerWrapperNew{
  width: 100%;
  background:#e1e1e1 url('../../Assets/Images/banner.jpg') no-repeat;
  height: calc(100vh - 0px);
  background-size: cover;
  background-position: top center;
  position: relative;
  @include breakpoint-down(md) {
    top: -10px;
    height: 50vh;
  }
  .owl-carousel{
    position: relative;
    .owl-stage-outer{
      .owl-stage{
        .owl-item{
          height: 100vh;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @include breakpoint-down(md) {
            height: 50vh;
          }
          .itemCover{
              width: 70%;
              text-align: center;
            .item{
                width: 100%;
              h2{
                color: $white-color;
                font-size: 40px;
                line-height: 50px;
                margin-bottom: 1rem;
                font-weight: 500;
                letter-spacing: 1.5px;
                @include breakpoint-down(md) {
                  font-size: 35px;
                  line-height: 50px;
                  font-weight: 700;
                }
              }
              p{
                color: $white-color;
                width: 80%;
                margin: 0 auto;
                font-size: 16px;
                line-height: 25px;
                letter-spacing: 1px;
              }
            }
          }
        }
      }
    }
    .owl-nav{
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      width: 100%;
      padding: 0 2rem;
      @include breakpoint-down(sm) {
        display: none;
      }
      .owl-prev{
        display: none;
        width: 58px;
        height: 58px;
        font-size: 0;
        background-color: transparent;
        border: 1px solid $white-color;
        border-radius: 50%;
        transition: all 0.6s ease;
        span{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 10px;
          background: url('../../Assets/Images/banner-white-arrow.png') no-repeat;
          background-size: 21px;
          transform: rotate(180deg);
          background-position: center center;
        }
        &:hover{
          transform: scale(1);
          transition: all 0.4s ease;
          width: 64px;
          height: 64px;
          background-color: $white-color;
        }
        &:hover span{
          background: url('../../Assets/Images/arrow_icon_hover.png') no-repeat;
          transition: all 0.5s ease;
          background-size: 21px;
          transform: rotate(180deg);
          background-position: center center;
        }
      }
      .owl-next{
        display: none;
        width: 58px;
        height: 58px;
        font-size: 0;
        background-color: transparent;
        border: 1px solid $white-color;
        border-radius: 50%;
        transition: all 0.6s ease;
        span{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 10px;
          background: url('../../Assets/Images/banner-white-arrow.png') no-repeat;
          background-size: 21px;
          transform: rotate(0deg);
          background-position: center center;
        }
        &:hover{
          transform: scale(1);
          transition: all 0.4s ease;
          width: 64px;
          height: 64px;
          background-color: $white-color;
        }
        &:hover span{
          background: url('../../Assets/Images/arrow_icon_hover.png') no-repeat;
          transition: all 0.5s ease;
          background-size: 21px;
          transform: rotate(0deg);
          background-position: center center;
        }
      }
    }
  }
}
